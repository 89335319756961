<template>
  <div>
    <b-card
      title="เลือกเดือน"
    >
      <b-form-group>
        <flat-pickr
          v-model="selMonth"
          class="form-control"
          :config="config"
          @on-change="selectMonth"
        />
      </b-form-group>
    </b-card>

    <b-card>
      <b-table
        responsive
        bordered
        :fields="columns"
        :items="data"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormGroup, BTable } from 'bootstrap-vue'
import lottoService from '@/service/lottoService'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const date = new Date()

export default {
  components: {
    BTable,
    BFormGroup,
    BCard,
    flatPickr,
  },
  data() {
    return {
      config: {
        plugins: [
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'm/Y',
          }),
        ],
      },
      selMonth: this.ParseDate(
        new Date(date.getFullYear(), date.getMonth(), 1),
      ),
      selMonthString: this.ParseDate(
        new Date(date.getFullYear(), date.getMonth(), 1),
      ),
      filter: '',
      loading: false,
      pagination: {
        sortBy: 'date',
        descending: true,
        page: 1,
        rowsPerPage: 0,
        rowsNumber: 10,
      },
      columns: [
        {
          key: 'date',
          label: 'วันที่',
          class: 'text-nowrap',
        },
        {
          key: 'all',
          label: 'ทั้งหมด',
          class: 'text-nowrap',
        },
      ],
      data: [],
      status: 0,
      sum_data: {},
      pagination_reg: {
        sortBy: 'name',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    ParseDate(d) {
      return `${this.pad2(d.getMonth() + 1)}/${d.getFullYear()}`
    },
    ParseDate2(d) {
      return (
        `${this.pad2(d.getDate())
        }/${
          this.pad2(d.getMonth() + 1)
        }/${
          d.getFullYear()}`
      )
    },
    selectMonth(e) {
      // eslint-disable-next-line no-unreachable
      const d = this.ParseDate(
        new Date(e[0].getFullYear(), e[0].getMonth(), 1),
      )
      this.selMonthString = d
      this.reload()
    },
    async reload() {
      this.data = []
      this.sum_data = {}
      this.loading = true

      const [res1, res2] = await Promise.all([
        lottoService.getAuthen('huay/dict'),
        lottoService.getAuthen(`summary/month/?month=${this.selMonthString}`),
      ])

      if (res1.status === 200) {
        const data = [{ id: '0', name: 'หวยยี่กี' }, ...res1.data]
        // eslint-disable-next-line no-restricted-syntax
        for (const v of data) {
          this.columns.push({
            label: v.name,
            key: v.id,
            class: 'text-nowrap',
          })
        }
      }

      if (res2.status === 200) {
        const resData = res2.data.data
        // eslint-disable-next-line no-shadow,guard-for-in,no-restricted-syntax
        for (const date in resData) {
          const d = {}
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const key in resData[date]) {
            const value = resData[date][key].pay
            d[key] = this.$store.state.app.toNumber(value.toFixed(2))
          }

          this.data.push({
            date,
            ...d,
          })
        }

        const { summary } = res2.data
        const d = {}
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in summary) {
          const value = summary[key].pay
          d[key] = this.$store.state.app.toNumber(value.toFixed(2))
        }
        this.data.push({
          date: 'รวม',
          ...d,
        })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
